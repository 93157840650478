import { render, staticRenderFns } from "./basicInformation.vue?vue&type=template&id=ea34b732&scoped=true&"
import script from "./basicInformation.vue?vue&type=script&lang=js&"
export * from "./basicInformation.vue?vue&type=script&lang=js&"
import style0 from "./basicInformation.vue?vue&type=style&index=0&id=ea34b732&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea34b732",
  null
  
)

export default component.exports