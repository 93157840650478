<template>
  <div class="container_box details_box_styles">
    <!--企业信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1002041') }}</h2>
        </template>
        <Form :model="enterpriseInfoForm" ref="enterpriseInfoForm" :rules="enterpriseInfoRuleValidate" :label-width="170" @submit.native.prevent>
          <Row>
            <languagesTabs ref="languagesTab" :tabsList="multilingualNamesList">
              <template v-for="item in multilingualNamesList">
                <template :slot="item.name">
                  <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
                    <Form-item :label="labelRander(item)" :prop="'name_' + item.code" :rules="getRules(item)">
                      <span v-if="!editPriseInfo">{{ enterpriseInfoForm['name_' + item.code] || '-' }}</span>
                      <Input v-else v-model.trim="enterpriseInfoForm['name_' + item.code]" clearable :maxlength="255" class="width_350"></Input>
                    </Form-item>
                  </Col>
                </template>
              </template>
            </languagesTabs>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <Form-item :label="$t('key1002012')" class="required_style_label">
                <Upload
                  class="upload_styles"
                  ref="upload"
                  type="drag"
                  name="files"
                  v-if="editPriseInfo"
                  :headers="headObj"
                  :action="fileApi"
                  :data="uploadData"
                  :show-upload-list="false"
                  :on-success="(response, file, fileList)=> uploadSuccess(response, file, fileList, 'businessLicensePath')"
                  :format="['jpg', 'jpeg', 'png']"
                  :max-size="20480"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize">
                  <div class="img_style" v-if="enterpriseInfoForm.businessLicensePath">
                    <img :src="setImgPath(enterpriseInfoForm.businessLicensePath)" alt=""/>
                  </div>
                  <div class="upload_icon_box" v-else>
                    <Icon type="ios-cloud-upload" size="45" style="color: #3399ff"></Icon>
                    <p>{{ $t('key1000402') }}</p>
                  </div>
                </Upload>
                <img v-else class="viewImgStyles" v-viewer="viewerOptions" :src="setImgPath(enterpriseInfoForm.businessLicensePath)" alt=""/>
                <p class="font-size-12">{{ $t('key1002042') }}</p>
              </Form-item>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <Form-item :label="$t('key1002013')" class="required_style_label">
                <div class="flex align-items-center">
                  <Upload
                    class="upload_styles"
                    ref="upload"
                    type="drag"
                    name="files"
                    v-if="editPriseInfo"
                    :headers="headObj"
                    :action="fileApi"
                    :data="uploadData"
                    :show-upload-list="false"
                    :on-success="(response, file, fileList)=> uploadSuccess(response, file, fileList, 'identificationCardPath')"
                    :format="['jpg', 'jpeg', 'png']"
                    :max-size="20480"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize">
                    <div class="img_style" v-if="enterpriseInfoForm.identificationCardPath">
                      <img :src="setImgPath(enterpriseInfoForm.identificationCardPath)" alt=""/>
                    </div>
                    <div class="upload_icon_box" v-else>
                      <Icon type="ios-cloud-upload" size="45" style="color: #3399ff"></Icon>
                      <p>{{ $t('key1002043') }}</p>
                    </div>
                  </Upload>
                  <img v-else class="viewImgStyles mr20" v-viewer="viewerOptions" :src="setImgPath(enterpriseInfoForm.identificationCardPath)" alt=""/>
                  <Upload
                    class="upload_styles ml20"
                    ref="upload"
                    type="drag"
                    name="files"
                    v-if="editPriseInfo"
                    :headers="headObj"
                    :action="fileApi"
                    :data="uploadData"
                    :show-upload-list="false"
                    :on-success="(response, file, fileList)=> uploadSuccess(response, file, fileList, 'identificationCardBackPath')"
                    :format="['jpg', 'jpeg', 'png']"
                    :max-size="20480"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize">
                    <div class="img_style" v-if="enterpriseInfoForm.identificationCardBackPath">
                      <img :src="setImgPath(enterpriseInfoForm.identificationCardBackPath)" alt=""/>
                    </div>
                    <div class="upload_icon_box" v-else>
                      <Icon type="ios-cloud-upload" size="45" style="color: #3399ff"></Icon>
                      <p>{{ $t('key1002044') }}</p>
                    </div>
                  </Upload>
                  <img v-else class="viewImgStyles" v-viewer="viewerOptions" :src="setImgPath(enterpriseInfoForm.identificationCardBackPath)" alt=""/>
                </div>
                <p class="font-size-12">{{ $t('key1002042') }}</p>
              </Form-item>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002014')" prop="scale">
                <span v-if="!editPriseInfo" class="labelText">{{ enterpriseInfoForm.scaleName }}</span>
                <Select v-else v-model="enterpriseInfoForm.scale" transfer filterable :placeholder="$t('key1000087')" class="width_350">
                  <Option v-for="item in scaleList" :key="item.value" :value="item.value">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002015')" prop="contacts">
                <span v-if="!editPriseInfo" class="labelText">{{ enterpriseInfoForm.contacts || '-' }}</span>
                <Input v-else v-model.trim="enterpriseInfoForm.contacts" :placeholder="$t('key1002016')" clearable class="width_350"></Input>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002017')" prop="contactsPhone">
                <span v-if="!editPriseInfo" class="labelText">{{ enterpriseInfoForm.contactsPhone || '-' }}</span>
                <Input v-else v-model.trim="enterpriseInfoForm.contactsPhone" :placeholder="$t('key1002018')" clearable class="width_350"></Input>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002019')" prop="phone">
                <span v-if="!editPriseInfo" class="labelText">{{ enterpriseInfoForm.phone || '-' }}</span>
                <Input v-else v-model.trim="enterpriseInfoForm.phone" :placeholder="$t('key1002020')" clearable class="width_350"></Input>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1001984')" prop="email">
                <span v-if="!editPriseInfo" class="labelText">{{ enterpriseInfoForm.email || '-' }}</span>
                <Input v-else v-model.trim="enterpriseInfoForm.email" :placeholder="$t('key1002011')" clearable class="width_350"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
      <div class="flex align-items-center p16 back-fff" v-if="getPermission('ymsSupplierMerchant_updateSupplierCompanyInfo')">
        <Button class="mr15" v-if="!editPriseInfo" type="primary" @click="modifyBtn">{{ $t('key1002812') }}</Button>
        <div v-else class="flex align-items-center">
          <Button class="mr15" type="primary" @click="submitForReviewBtn">{{ $t('key1002357') }}</Button>
          <Button class="mr15" @click="cancelBtn('prise')">{{ $t('key1000098') }}</Button>
        </div>
      </div>
    </div>
    <!--店铺信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1002045') }}</h2>
        </template>
        <Form :model="enterpriseStoreForm" ref="enterpriseStoreForm" :rules="enterpriseStoreRuleValidate" :label-width="170" @submit.native.prevent>
          <Row>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002021')" prop="shopName">
                <span v-if="!editStoreInfo" class="labelText">{{ enterpriseStoreForm.shopName || '-' }}</span>
                <Input v-else v-model.trim="enterpriseStoreForm.shopName" :placeholder="$t('key1002022')" clearable class="width_350"></Input>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002023')" prop="shopLogoPath" class="required_style_label">
                <Upload
                  class="upload_styles"
                  ref="upload"
                  type="drag"
                  name="files"
                  v-if="editStoreInfo"
                  :headers="headObj"
                  :action="fileApi"
                  :data="uploadData"
                  :show-upload-list="false"
                  :on-success="(response, file, fileList)=> uploadSuccess(response, file, fileList, 'shopLogoPath')"
                  :format="['jpg', 'jpeg', 'png']"
                  :max-size="20480"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize">
                  <div class="img_style" v-if="enterpriseStoreForm.shopLogoPath">
                    <img :src="setImgPath(enterpriseStoreForm.shopLogoPath)" alt=""/>
                  </div>
                  <div class="upload_icon_box" v-else>
                    <Icon type="ios-cloud-upload" size="45" style="color: #3399ff"></Icon>
                    <p>{{ $t('key1000402') }}</p>
                  </div>
                </Upload>
                <img v-else class="viewImgStyles" v-viewer="viewerOptions" :src="setImgPath(enterpriseStoreForm.shopLogoPath)" alt=""/>
                <p class="font-size-12">{{ $t('key1002046') }}</p>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002024')" prop="managementModel">
                <span class="labelText">{{ enterpriseStoreForm.managementModel || '-' }}</span>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002025')" prop="plannedBusinessCategory">
                <span class="labelText">
                  {{ enterpriseStoreForm.plannedBusinessCategory.length > 0 ? enterpriseStoreForm.plannedBusinessCategory : '-' }}
                </span>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002026')" prop="shelvesProductsNumber" class="width_350">
                <span class="labelText">{{ enterpriseStoreForm.shelvesProductsNumber || '-' }}</span>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002027')" prop="productReleaseNumber" class="reset_input_styles">
                <span class="labelText">{{ enterpriseStoreForm.productReleaseNumber || '-' }}</span>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1002029')" prop="businessAddress">
                <span class="labelText">{{ enterpriseStoreForm.businessAddress ? enterpriseStoreForm.businessAddress : '-' }}</span>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem prop="address">
                <span class="labelText">{{ enterpriseStoreForm.address || '-' }}</span>
              </FormItem>
            </Col>
            <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
              <FormItem :label="$t('key1005666')">
                <div class="flex align-items-center">
                  <span class="basic_txt" style="margin-right: 8px;">{{ currencyName }}</span>
                  <span class="themeColor cursor"
                    @click="$refs['changeCurrency'].initCurrencyData('editSupplier', 2, currency)">
              {{ $t('key1004587') }} >
            </span>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
      <div class="flex align-items-center p16 back-fff" v-if="getPermission('ymsSupplierMerchant_updateSupplierRegisterInfo')">
        <Button class="mr15" v-if="!editStoreInfo" type="primary" @click="editStoreInfo = true">{{ $t('key1002812') }}</Button>
        <div v-else class="flex align-items-center">
          <Button class="mr15" type="primary" @click="enterpriseStoreBtn">{{ $t('key1000097') }}</Button>
          <Button class="mr15" @click="cancelBtn('store')">{{ $t('key1000098') }}</Button>
        </div>
      </div>
    </div>
    <div class="basic_information_box">
      <!--仓库配置-->
      <Card :bordered="false" dis-hover v-if="pageParams.isWarehouseManagement === 'Y'">
        <template #title>
          <h2 class="title_box">{{ $t('key1005668') }}</h2>
        </template>
        <Row type="flex" :gutter="gutterItem">
          <Col :span="ItemCol" class="basic_item">
            <div v-if="editType" class="warehouseConfig_box">
              <Checkbox :disabled="pageParams.isSupportTailCarrierService === 1"
                v-model="pageParams.isWarehouseManagement" true-value="Y" false-value="N">{{ $t('key1005669') }}
              </Checkbox>
              <span class="warehouseConfig_box_item" @click="changeValue"></span>
            </div>
            <span class="basic_title font-size-12" v-else>{{ warehouseManageText }}</span>
          </Col>
        </Row>
      </Card>
      <!--发货地址信息-->
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1005670') }}</h2>
        </template>
        <div class="shippingAddress_box" v-for="(item,index) in shippingAddressList" :key="index">
          <div class="flex align-items-center mb20">
            <h2 class="title">{{ $t('key1003289') + (index + 1) }}</h2>
            <Icon type="ios-trash" color="rgb(225 31 18)" size="24" class="cursor ml5"
              v-if="shippingAddressList.length>1 && editType" @click="deleteAddressBtn(item, index)"/>
          </div>
          <Form :ref="index + '_shippingAddressInfo'" :model="item" :label-width="110" @submit.native.prevent>
            <Row type="flex" :gutter="gutterItem">
              <Col :span="ItemCol">
                <Form-item :label="$t('key1002015')" prop="name"
                  :rules="{required: true, message: $t('key1005665'),trigger: 'blur'}">
                  <Input v-model.trim="item.name" clearable v-if="editType"></Input>
                  <span v-else class="font-size-12">{{ item.name }}</span>
                </Form-item>
              </Col>
              <Col :span="ItemCol">
                <Form-item :label="$t('key1004508')" prop="phone"
                  :rules="{trigger: 'blur', required: true, validator: validateMobile()}">
                  <Input v-model.trim="item.phone" clearable v-if="editType"></Input>
                  <span v-else class="font-size-12">{{ item.phone }}</span>
                </Form-item>
              </Col>
              <Col :span="ItemCol">
                <FormItem :label="$t('key1003543')" prop="countryCode"
                  :rules="{required: true, message: $t('key1000839'),trigger: 'change'}">
                  <Select v-model="item.countryCode" filterable transfer v-if="editType">
                    <Option v-for="(item, index) in countrysList" :key="index" :value="item.twoCode">{{ item.cnName }}</Option>
                  </Select>
                  <span v-else class="font-size-12">{{ item.countryCodeName }}</span>
                </FormItem>
              </Col>
              <Col :span="ItemCol">
                <FormItem :label="$t('key1005667')" prop="addressList"
                  :rules="{required: true, validator: validateCascaderAddressValue(), trigger: 'change'}">
                  <Cascader
                    v-if="editType"
                    :disabled="item.countryCode === null"
                    v-model="item.addressList"
                    :data="addressBasicData"
                    filterable
                    change-on-select>
                  </Cascader>
                  <span v-else class="font-size-12">{{ item.addressName }}</span>
                </FormItem>
              </Col>
              <Col :span="twoItemCol">
                <Form-item :label="$t('key1004999')" prop="address"
                  :rules="{required: true, message: $t('key1002067'),trigger: 'blur'}">
                  <Input v-model.trim="item.address" clearable v-if="editType"></Input>
                  <span v-else class="font-size-12">{{ item.address }}</span>
                </Form-item>
              </Col>
            </Row>
          </Form>
        </div>
        <h3 class="cursor themeColor mt5 display" v-if="editType" style="padding-bottom: 30px; display: inline-block;"
          @click="addAddressBtn">{{ $t('key1005671') }}</h3>
      </Card>
      <div class="flex align-items-center p16 back-fff" v-if="getPermission('address_save')">
        <Button class="mr15" v-if="!editType" type="primary" :disabled="addressTalg" @click="editType = true">{{ $t('key1002812') }}</Button>
        <div v-else class="flex align-items-center">
          <Button class="mr15" type="primary" @click="shippingAddressBtn">{{ $t('key1000097') }}</Button>
          <Button class="mr15" @click="cancelAddresBtn">{{ $t('key1000098') }}</Button>
        </div>
      </div>
    </div>
    <!--编辑币种弹窗-->
    <changeCurrencyModal ref="changeCurrency" @updateData="getSystemCurrencyData()"></changeCurrencyModal>
    <!--审核中提示-->
    <Alert class="alert_content_box" type="warning" show-icon v-if="pageParams.updateSupplierAuditStatus === 1">
      {{ $t('key1005672') }}
    </Alert>
    <!--审核驳回提示-->
    <Alert class="alert_content_box" type="error" show-icon v-if="pageParams.updateSupplierAuditStatus === 0">
      <div class="flex align-items-center">
        <span>{{ $t('key1005673') }}</span>
        <span class="themeColor cursor ml10" @click="reasonForRejectionModal = true">{{ $t('key1005674') }}</span>
      </div>
    </Alert>
    <!--审核成功提示-->
    <Alert v-if="pageParams.updateSupplierAuditStatus === 2" class="alert_content_box" type="success" show-icon closable>
      <span>{{ $t('key1005675') }}</span>
      <span class="themeColor cursor ml10" @click="getUpdateSupplierEnterpriseInfo">{{ $t('key1000796') }}</span>
    </Alert>
    <!--驳回原因弹窗-->
    <Modal v-model="reasonForRejectionModal" :title="$t('key1000506')" :width="700" :transfer="false" :mask-closable="true" footer-hide>
      <div class="font-size-13 color-333">{{ pageParams.auditRemarks }}</div>
    </Modal>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import changeCurrencyModal from "@/components/common/changeCurrencyModal";
import languagesTabs from "@/components/common/languagesTabs.vue";
import {currencyNameCode} from "@/utils/common";

export default {
  name: "basicInformation",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      ItemCol: 6,
      twoItemCol: 12,
      pageParams: {},
      currencyName: '',
      currency: null,
      shippingAddressList: [
        {
          address: '',
          city: null,
          countryCode: null,
          district: null,
          name: '',
          phone: '',
          state: null,
          type: 'pickup', // 揽收人地址
          addressList: []
        }
      ],
      addressBasicData: [],
      editType: false,
      countrysList: [],
      warehouseManageText: alias47916751af154eb5b47cd5de9d34633d.t('key1005676'),
      editPriseInfo: false,
      editStoreInfo: false,
      //企业信息
      enterpriseInfoForm: {
        businessLicensePath: '', // 营业执照
        identificationCardBackPath: null, // 法人身份证国徽面
        identificationCardPath: null, // 法人身份证人像面
        scale: null, // 经营规模:1.10人以下、2.10~30人、3.30~50人、4.50~100人、5.100人以上
        contacts: '', // 联系人
        contactsPhone: '', // 联系人手机号码
        phone: '', // 固定电话
        email: '', // 邮箱
        scaleName: '',
      },
      enterpriseInfoRuleValidate: {
        scale: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1002061'), trigger: 'change', type: 'number'}],
        contacts: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1002062'), trigger: 'blur'}],
        contactsPhone: [{required: true, validator: v.validateMobile(), trigger: 'blur'}],
        phone: [{required: false, validator: v.validateFixedTelephone(), trigger: 'blur'}],
        email: [{validator: v.validateMailboxFormat(false), trigger: 'blur'}],
      },
      // 店铺信息
      enterpriseStoreForm: {
        shopName: '', // 店铺名称
        shopLogoPath: null, // 店铺logo
        managementModel: null, // 经营模式 1.自有工厂、2.渠道代理、3.经销批发
        plannedBusinessCategory: [], //  类目:1.经营类目  类目值:1.女装、2.男装、3.男童、4.女童、5.婴童
        shelvesProductsNumber: null, // 预计上架商品数  1.10以下 2.10~30 3.30~50 4.50~100 5.100以上
        productReleaseRate: null, // 商品发布频率:0.待定、1.每天发布、2.每周发布、3.每月发布
        productReleaseNumber: null, // 商品发布数
        businessAddress: null, // 营业地址
        address: '', // 详细地址
      },
      enterpriseStoreRuleValidate: {
        shopName: [{required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1002063'), trigger: 'blur'}],
      },
      scaleList: [
        {value: 1, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002072')},
        {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002073')},
        {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002074')},
        {value: 4, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002075')},
        {value: 5, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002076')}
      ], // 经营规模
      managementModelList: [
        {value: 1, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002077')},
        {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002078')},
        {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002079')}
      ], // 经营模式
      plannedBusinessCategoryList: [
        {value: 1, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002080')},
        {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002081')},
        {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002082')},
        {value: 4, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002083')},
        {value: 5, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002084')}
      ], // 计划经营类目
      groundingList: [
        {value: 1, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002085')},
        {value: 2, name: '10~30'},
        {value: 3, name: '30~50'},
        {value: 4, name: '50~100'},
        {value: 5, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002086')}
      ], // 预计上架商品数
      releaseTypeList: [
        {value: 0, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002087')},
        {value: 1, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002088')},
        {value: 2, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002089')},
        {value: 3, name: alias47916751af154eb5b47cd5de9d34633d.t('key1002090')},
      ], // 发布类型
      stateData: [],
      ymsMerchantStoreId: null,
      fileApi: api.erpCommon_uploadFiles + '?basePath=yms-core-service&action="uploadimage"',
      uploadData: {},
      labelRander: (params) => {
        return alias47916751af154eb5b47cd5de9d34633d.t('key1002098') + params.title + alias47916751af154eb5b47cd5de9d34633d.t('key1001837');
      },
      addressTalg: true,
      originalWarehouseConfig: null,
      reasonForRejectionModal: false,
    };
  },
  computed: {
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  created() {
    this.initMultilingualFields('enterpriseInfoForm', this.enterpriseInfoForm, ['name_']);
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.getCountrysData().then((data) => {
          if (data && data.length > 0) {
            this.countrysList = data.filter((ele) => {
              return ele.twoCode === 'CN'
            });
            // 默认拿国内地址
            this.getProvinceBasicData('CN').then((list) => {
              if (list.length > 0) {
                this.addressBasicData = list;
                this.getAddressData();
              }
            });
          }
        });
        await this.getCurrencyInfo();
        await this.getSystemCurrencyData();
        await this.getSuppliersInfo();
      })();
    },
    // 关闭审核成功提示
    getUpdateSupplierEnterpriseInfo() {
      let v = this;
      v.axios.get(api.get_ymsSupplierMerchant_closeAuditTips + v.userInfo.merchantId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.pageParams.updateSupplierAuditStatus = null;
          }
        }
      });
    },

    // 获取供应商基本信息
    getSuppliersInfo(type) {
      let v = this;
      v.ymsMerchantStoreId = null;
      v.axios.get(api.get_ymsSupplierMerchant_info).then(response => {
        if (response.data.code === 0) {
          let details = response.data.datas;
          if (details) {
            details.isWarehouseManagement = details.isWarehouseManagement ? details.isWarehouseManagement : 'N';
            v.pageParams = details;
            let obj = v.handleMultilingualFormFields(details, ['name']);
            let enterpriseInfo = Object.assign({}, details, obj);

            // 处理企业信息
            function processingEnterpriseInfo(obj) {
              v.enterpriseInfoForm = obj;
              let keyList = [
                'businessLicensePath',
                'identificationCardBackPath',
                'identificationCardPath',
                'contacts',
                'contactsPhone',
                'phone',
                'email',
              ];
              keyList.map((key) => {
                v.enterpriseInfoForm[key] = obj[key];
              });
              v.scaleList.map((ele) => {
                if (ele.value === obj.scale) {
                  v.enterpriseInfoForm['scaleName'] = ele.name;
                }
              });
            }

            // 处理店铺信息
            function processingStoreInfo(obj) {
              // 店铺信息
              let storesObj = obj.ymsMerchantStore;
              if (storesObj) {
                v.ymsMerchantStoreId = storesObj.ymsMerchantStoreId;
                let storeskeyList = ['shopLogoPath', 'productReleaseNumber'];
                storeskeyList.map((key) => {
                  v.enterpriseStoreForm[key] = storesObj[key];
                });
                let selectStoreskeyList = ['managementModel', 'shelvesProductsNumber', 'productReleaseRate'];
                let selectStoresKeyObj = {
                  managementModel: v.managementModelList,
                  shelvesProductsNumber: v.groundingList,
                  productReleaseRate: v.releaseTypeList,
                };

                selectStoreskeyList.map((key) => {
                  let list = selectStoresKeyObj[key];
                  if (list.length > 0) {
                    list.map((ele) => {
                      if (ele.value === storesObj[key]) {
                        v.enterpriseStoreForm[key] = ele.name;
                      }
                    })
                  }
                });
                let productReleaseName = v.enterpriseStoreForm.productReleaseRate + v.enterpriseStoreForm.productReleaseNumber + alias47916751af154eb5b47cd5de9d34633d.t('key1002047');
                v.enterpriseStoreForm.productReleaseNumber = v.enterpriseStoreForm.productReleaseRate !== alias47916751af154eb5b47cd5de9d34633d.t('key1002087') ? productReleaseName : v.enterpriseStoreForm.productReleaseRate;
                v.enterpriseStoreForm.shopName = storesObj.name;
                let ymsMerchantStoreAttributes = storesObj.ymsMerchantStoreAttributes || [];
                if (ymsMerchantStoreAttributes.length > 0) {
                  v.ymsMerchantStoreAttributes = ymsMerchantStoreAttributes;
                  let categoryList = [];
                  ymsMerchantStoreAttributes.map((item) => {
                    v.plannedBusinessCategoryList.map((ele) => {
                      if (ele.value === item.attributeValue) {
                        categoryList.push(ele.name);
                      }
                    })
                  })
                  v.enterpriseStoreForm.plannedBusinessCategory = categoryList.join('、');
                }
                // 处理地址信息
                let ymsMerchantStoreAddresses = storesObj.ymsMerchantStoreAddresses || [];
                v.enterpriseStoreForm.businessAddress = '';
                if (ymsMerchantStoreAddresses.length > 0) {
                  let addressesInfo = ymsMerchantStoreAddresses[0];
                  v.enterpriseStoreForm.businessAddress = v.handleAddressName(addressesInfo);
                  v.enterpriseStoreForm.address = obj.address ? obj.address : addressesInfo.street;
                }
              }
            }

            if (type) {
              if (type === 'updateStore') {
                processingStoreInfo(details);
              } else {
                processingEnterpriseInfo(enterpriseInfo);
              }
            } else {
              processingEnterpriseInfo(enterpriseInfo);
              processingStoreInfo(details);
            }
            v.originalWarehouseConfig = details.isWarehouseManagement;
            v.$store.commit('isWarehouseManagement', v.pageParams.isWarehouseManagement);
            localStorage.setItem('topNavActiveName', 'operationManage');
            v.$forceUpdate();
          }
        }
      });
    },
    // 获取地址信息数据
    getAddressData() {
      let v = this;
      v.shippingAddressList = [];
      v.addressTalg = true;
      if (v.countrysList.length > 0) {
        v.axios.get(api.get_address_query + `?type=pickup`, {loading: true}).then(response => {
          v.addressTalg = false;
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              data.map((item) => {
                item.countryCodeName = alias47916751af154eb5b47cd5de9d34633d.t('key1001203');
                item.addressName = v.handleAddressName(item);
                item.addressList = [item.state, item.city, item.district];
                item.countryId = v.countrysList[0].countryId
              });
              v.shippingAddressList = data;
              v.$forceUpdate();
            }
          }
        });
      }
    },
    // 获取系统配置的币种
    getSystemCurrencyData() {
      let v = this;
      let currency = v.$store.state.erpConfig.currency;
      if (currency) {
        v.currencyName = currencyNameCode(currency);
        v.currency = currency;
      }
      v.axios.post(api.post_erpCommonSettingParam_queryMerchantByParamKeys, ['ymsSupplierCurrency']).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data.length > 0) {
            data.map((item) => {
              if (item.paramKey === 'ymsSupplierCurrency') {
                v.currency = item.paramValue;
                v.currencyName = currencyNameCode(item.paramValue);
                v.$forceUpdate();
              }
            })
          }
        }
      })
    },
    // 添加发货地址
    addAddressBtn() {
      this.shippingAddressList.push({
        address: '',
        city: null,
        countryCode: null,
        district: null,
        name: '',
        phone: '',
        state: null,
        type: 'pickup',
        addressList: []
      });
    },
    // 删除地址
    deleteAddressBtn(item, index) {
      let v = this;
      v.shippingAddressList.splice(index, 1);
    },
    // 保存仓库配置、地址信息按钮
    async shippingAddressBtn() {
      let v = this;
      let arr = [];
      await v.shippingAddressList.forEach((item, index) => {
        let dom = index + '_shippingAddressInfo';
        v.$refs[dom][0].validate((valid) => {
          if (valid == false) {
            return arr.push(item);
          }
        });
      });
      //全部通过验证规则
      if (arr.length === 0) {
        let newList = [];
        v.shippingAddressList.map((item) => {
          let {addressName, countryCodeName, addressList, ...obj} = item;
          obj['state'] = item.addressList[0];
          obj['city'] = item.addressList[1];
          obj['district'] = item.addressList[2];
          newList.push(obj);
        });
        let query = {
          insertAddressBos: [],
          isWarehouseManagement: v.pageParams.isWarehouseManagement
        }
        if (newList.length > 0) {
          query.insertAddressBos = newList;
        }
        v.axios.post(api.post_address_save, query, {loading: true, loaddingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
          if (response.data.code === 0) {
            v.editType = false;
            v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000131'));
            v.getSuppliersInfo();
            v.getAddressData();
          }
        });
      }
    },
    // 取消编辑仓库配置、地址信息
    cancelAddresBtn() {
      let v = this;
      v.editType = false;
      v.shippingAddressList.forEach((item, index) => {
        let dom = index + '_shippingAddressInfo';
        v.$refs[dom][0].resetFields();
      });
      v.pageParams.isWarehouseManagement = JSON.parse(JSON.stringify(v.originalWarehouseConfig));
      v.getAddressData();
    },
    // （营业执照、法人身份证、店铺logo）--上传成功
    uploadSuccess(response, file, fileList, type) {
      let v = this;
      let uploadList = [
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1002107'), key: 'businessLicensePath'},
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1002108'), key: 'identificationCardBackPath'},
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1002109'), key: 'identificationCardPath'},
        {name: alias47916751af154eb5b47cd5de9d34633d.t('key1002110'), key: 'shopLogoPath'},
      ];
      uploadList.map((item) => {
        if (item.key === type) {
          let data = response.datas[0];
          if (response.code === 0) {
            if (item.key === 'shopLogoPath') {
              v.enterpriseStoreForm[item.key] = data;
            } else {
              v.enterpriseInfoForm[item.key] = data;
            }
            v.$Message.success(`${alias47916751af154eb5b47cd5de9d34633d.t('key1002056')}${item.name}${alias47916751af154eb5b47cd5de9d34633d.t('key1001107')}`);
          } else {
            v.$Message.error(`${alias47916751af154eb5b47cd5de9d34633d.t('key1002056')}${item.name}${alias47916751af154eb5b47cd5de9d34633d.t('key1001108')}`);
          }
        }
      });
    },
    // 校验文件格式
    handleFormatError(file) {
      let v = this;
      v.$Message.error({
        content: `${alias47916751af154eb5b47cd5de9d34633d.t('key1002057')}${file.name}${alias47916751af154eb5b47cd5de9d34633d.t('key1002058')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1002059')}`,
        duration: 5
      });
    },
    // 限制上传的文件不能超过20M
    handleMaxSize(file) {
      let v = this;
      v.$Message.error({
        content: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000066')}${file.name}${alias47916751af154eb5b47cd5de9d34633d.t('key1000067')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1000068')}`,
        duration: 5
      });
    },
    // 修改企业信息
    modifyBtn() {
      if (this.pageParams.updateSupplierAuditStatus === 1) {
        this.$Modal.warning({
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000260'),
          content: alias47916751af154eb5b47cd5de9d34633d.t('key1005677'),
          okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000205'),
        });
      } else {
        this.editPriseInfo = true;
      }
    },
    // 取消编辑企业、店铺信息
    cancelBtn(type) {
      let v = this;
      v.$Modal.confirm({
        title: alias47916751af154eb5b47cd5de9d34633d.t('key1004923'),
        content: alias47916751af154eb5b47cd5de9d34633d.t('key1005678'),
        okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000443'),
        cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
        onOk: () => {
          if (type === 'store') {
            this.$refs['enterpriseStoreForm'].resetFields();
            this.editStoreInfo = false;
            this.$nextTick(() => {
              this.getSuppliersInfo('updateStore');
            })
          } else {
            this.$refs['enterpriseInfoForm'].resetFields();
            this.editPriseInfo = false;
            this.$nextTick(() => {
              this.getSuppliersInfo('updatePrise');
            })
          }
        },
        onCancel: () => {
        }
      });
    },
    // 提交审核
    submitForReviewBtn() {
      let v = this;
      let query = {
        name: v.handleMultilingualSingleParam(v.enterpriseInfoForm, 'name_'),
        ymsMerchantId: v.pageParams.ymsMerchantId
      }
      let keyList = [
        'scale',
        'contacts',
        'contactsPhone',
        'email',
        'phone',
        'businessLicensePath',
        'identificationCardBackPath',
        'identificationCardPath'
      ];
      let talg = true;
      v.$refs['enterpriseStoreForm'].validate((valid) => {
        if (valid) {
          // 校验上传的图片
          let uloadImgList = [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002111'), key: 'businessLicensePath'},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002112'), key: 'identificationCardBackPath'},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002113'), key: 'identificationCardPath'}
          ];
          for (let i = 0; i < uloadImgList.length; i++) {
            let item = uloadImgList[i];
            if (!v.enterpriseInfoForm[item.key]) {
              talg = false;
              v.$Message.warning(item.title);
              return false;
            }
          }
          for (let key of keyList) {
            query[key] = v.enterpriseInfoForm[key];
          }
          if (talg) {
            v.axios.post(api.post_ymsSupplierMerchant_updateSupplierCompanyInfo, query, {
              loading: true,
              loaddingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')
            }).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1001646'));
                v.getSuppliersInfo('updatePrise');
                v.editPriseInfo = false;
                v.$forceUpdate();
              }
            });
          }
        }
      });
    },
    // 保存店铺信息
    enterpriseStoreBtn() {
      let v = this;
      let query = {
        updateSupplierRegisterStoreInfo: {
          name: v.enterpriseStoreForm.shopName,
          shopLogoPath: v.enterpriseStoreForm.shopLogoPath,
          ymsMerchantStoreId: v.ymsMerchantStoreId || null
        },
        ymsMerchantId: v.pageParams.ymsMerchantId
      }
      v.$refs['enterpriseStoreForm'].validate((valid) => {
        if (valid) {
          if (!v.enterpriseStoreForm.shopLogoPath) {
            v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1002114'));
            return false;
          }
          v.axios.post(api.post_ymsSupplierMerchant_updateSupplierRegisterInfo, query, {
            loading: true,
            loaddingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')
          }).then(response => {
            if (response.data.code === 0) {
              v.editStoreInfo = false;
              v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1001646'));
              v.getSuppliersInfo('updateStore');
              v.$forceUpdate();
            }
          });
        }
      });
    },
    // 设置表单必填项校验规则
    getRules(item) {
      let v = this;
      let list = [];
      if (item.required) {
        list = [
          {required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1002098') + item.title + alias47916751af154eb5b47cd5de9d34633d.t('key1001840'), trigger: 'blur'}
        ];
      }
      return list;
    },
    // 仓库配置确认弹窗
    changeValue() {
      let v = this;
      v.$Modal.confirm({
        title: alias47916751af154eb5b47cd5de9d34633d.t('key1005679'),
        content: alias47916751af154eb5b47cd5de9d34633d.t('key1005680'),
        okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000097'),
        cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
        onOk: () => {
          v.pageParams.isWarehouseManagement = 'N';
        },
        onCancel: () => {
        }
      });
    }
  },
  watch: {
    'pageParams.isWarehouseManagement': {
      handler(value) {
        if (!this.editType) {
          if (value === 'Y') {
            this.warehouseManageText = alias47916751af154eb5b47cd5de9d34633d.t('key1005681');
          } else {
            this.warehouseManageText = alias47916751af154eb5b47cd5de9d34633d.t('key1005676');
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    languagesTabs,
    changeCurrencyModal
  }
};
</script>

<style lang="less" scoped>
/deep/ .ivu-form .ivu-form-item-label {
  font-size: 14px !important;
}

.details_box_styles {
  .basic_information_box {
    .viewImgStyles {
      width: 120px;
      height: 120px;
      display: inline-block;
      object-fit: cover;
      vertical-align: middle;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #D7D7D7;
      box-sizing: border-box;
      cursor: pointer;
    }

    /deep/ .upload_styles {
      .ivu-upload.ivu-upload-drag {
        width: 120px;
        height: 120px;
      }

      .upload_icon_box {
        width: 120px;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
      }

      .img_style {
        width: 120px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          object-fit: cover;
          vertical-align: middle;
        }
      }
    }

    .labelText {
      position: relative;
      top: 1px;
    }

    .basic_item {
      .img_styles {
        width: auto;
        height: 125px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        max-width: 300px;
      }
    }

    .shippingAddress_box {
      margin-bottom: 10px;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: #000;
      }
    }

    .warehouseConfig_box {
      position: relative;

      .warehouseConfig_box_item {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        cursor: pointer;
        margin: 0;
      }
    }
  }

  .alert_content_box {
    position: absolute;
    top: 69px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 910;

    /deep/ .ivu-alert-icon {
      font-size: 23px;
      top: 4px;
      left: 7px;
    }
  }
}
</style>
